import moment from 'moment';
import React from 'react';
import {
  CourseCardBody,
  CourseCardContainer,
  CourseCardName,
} from '../../../components/course/styles/FilteredCourseCardStyle';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import {
  determineCredit,
  printErrorMessageFromFQL,
} from '../../../common/utils';
const MySwal = withReactContent(Swal);

const AttendaceCard = ({ c, fetch, handleClose }) => {
  const { queries, client } = useFaunaContext();
  return (
    <>
      <CourseCardContainer>
        <div>
          <CourseCardBody>
            <CourseCardName>{c.course}</CourseCardName>
          </CourseCardBody>
          <CourseCardBody>{c.classroom}</CourseCardBody>
          <CourseCardBody>
            {moment(c.date.value).format('MMM DD HH:mm')}
          </CourseCardBody>
          <button
            className="px-5 py-2 my-4 bg-red-500 hover:bg-red-600 rounded-full text-white disabled:opacity-50"
            disabled={c.attended}
            onClick={() => {
              let addCatchUpCredit = determineCredit(
                moment(c.date.value),
                c,
                true
              );
              let catchUpText = '';
              if (addCatchUpCredit === false) {
                catchUpText =
                  'You will receive 0 point due to insufficient notice (2 hrs)';
              }
              if (addCatchUpCredit === 'catch_up_credit_tier_0') {
                catchUpText = 'You will recevie 1 🥉 Bronze Catch Up Point.';
              }
              if (addCatchUpCredit === 'catch_up_credit_tier_1') {
                catchUpText = 'You will recevie 1 🥈 Silver Catch Up Point.';
              }
              if (addCatchUpCredit === 'catch_up_credit_tier_2') {
                catchUpText = 'You will recevie 1 🥇 Gold Catch Up Point.';
              }
              Swal.queue([
                {
                  title: `Are you going to release - ${c.course} (${moment(
                    c.date.value
                  ).format('MMM DD HH:mm')})? \n ${catchUpText}`,
                  confirmButtonText: c.attended ? 'Attended' : 'Release',
                  showCancelButton: true,
                  icon: 'question',
                  // text: catchUpText,
                  showLoaderOnConfirm: true,
                  preConfirm: () => {
                    return queries.student
                      .release_class(client, c.ticket_id, addCatchUpCredit)
                      .then((response) => {
                        if (response.data) {
                          Swal.fire({
                            icon: 'success',
                            title: `Class released`,
                            text: '',
                          });
                          fetch();
                          handleClose();
                        }
                        if (response.errors) {
                          // printErrorMessageFromFQL(response.errors);

                          Swal.fire({
                            icon: 'error',
                            title: response.errors[0].description,
                            text: '',
                          });
                          fetch();
                          handleClose();
                        }
                      });
                  },
                },
              ]);
            }}
          >
            Release
          </button>
        </div>
      </CourseCardContainer>
    </>
  );
};

export default AttendaceCard;

import { useQuery } from 'react-query';
import { get_active_courses } from '../faunadb/queries/student';
import { useFaunaContext } from '../providers/FaunaProvider';

const useActiveEnrolledCourseHook = () => {
  const { client } = useFaunaContext();
  return useQuery(
    ['my_active_enrolled_course'],
    async () => await get_active_courses(client)
  );
};

export default useActiveEnrolledCourseHook;

import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { CourseCardDivideLine } from '../../../components/course/styles/FilteredCourseCardStyle';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import { printErrorMessageFromFQL } from '../../../common/utils';
import { toast } from 'react-toastify';
import Checkout from '../../../components/stripe/Checkout';
import { RiExchangeDollarLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { agreeTnc, updateStudentName } from '../../../faunadb/queries/student';
import { useFaunav10Context } from '../../../providers/FaunaV10Provider';
const Profile = ({ profile, user }) => {
  const [name, setName] = useState(profile.name);
  const [phoneNumber, setPhoneNumber] = useState(profile.phone_number);
  const [chargeValue, setChargeValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const { client: clientV10} = useFaunav10Context();

  const queryClient = useQueryClient();

  const agreeTncMutation = useMutation(agreeTnc,{
    onError: (error) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${error?.description}`,
        })
    },
    onSuccess: (data) => {
        queryClient.invalidateQueries(['my_profile']);
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `Term and Condition Agreed`,
        })
    },
  })

  return (
    <>
      <h2>User Profile</h2>
      <CourseCardDivideLine />
      <div className="w-full">
        <div className="w-full flex my-4">
          <div className="w-1/4 text-center">Email:</div>
          <div className="w-3/4 text-center">{profile.email}</div>
        </div>
        <div className="w-full flex my-4">
          <div className="w-1/4 text-center">Name:</div>
          <div className="w-3/4 text-center">
            <input
              type="text"
              className="border p-1"
              placeholder="Full Name..."
              value={name || ''}
              onChange={({ target }) => setName(target.value)}
            ></input>
          </div>
        </div>
        <div className="w-full flex my-4">
          <div className="w-1/4 text-center">Phone Number:</div>
          <div className="w-3/4 text-center">
            <input
              type="text"
              className="border p-1"
              placeholder="Phone Number..."
              value={phoneNumber || ''}
              onChange={({ target }) =>
                setPhoneNumber(
                  target.value
                    .replace(' ', '')
                    .replace(/([a-zA-Z ])/g, '')
                    .replace(/[^a-z0-9]+|\s+/gim, '')
                    .slice(0, 8)
                )
              }
            ></input>
          </div>
        </div>
        <div className="w-full flex my-4">
          <div className="w-1/4 text-center">Role:</div>
          <div className="w-3/4 text-center">
            {profile.vip ? 'VIP' : 'General'}
          </div>
        </div>
        <div className="w-full flex justify-center">
          <button
            className="px-11 py-2 bg-gray-400 hover:bg-gray-500 rounded-full text-white"
            onClick={() => {
              setLoading(true);
              updateStudentName(
                  clientV10,
                  name,
                  phoneNumber
                )
                .then((response) => {
                  if (response.data) {
                    toast.success('Updated!');
                    profile.name = name;
                    profile.phone_number = phoneNumber;
                    queryClient.invalidateQueries(['my_profile']);
                  }
                  if (response.errors) {
                    printErrorMessageFromFQL(response.errors);
                  }
                  setLoading(false);
                });
            }}
          >
            {!loading ? 'Update' : 'Loading...'}
          </button>
        </div>

        <div className='py-6'>
          <CourseCardDivideLine />
          <div className="w-full flex justify-center items-center flex-col py-4">
            <Link to="/tnc" className="text-center">Terms and Conditions</Link>
            {/* create a tailwind check box with onclick with text in same row*/}
            <div className="w-full flex justify-center items-center flex-row py-4">
              <input type="checkbox" className="m-2" disabled={profile?.tnc_agreed} checked={profile?.tnc_agreed} onChange={(v)=>{
                if(v.target.checked){
                  agreeTncMutation.mutate(clientV10);
                }
              }}/>
              <label className="text-center">I have read and agree to the <Link to="/tnc" className="text-center"><span className='underline'>T&C</span></Link></label>
              </div>
          </div>
          <CourseCardDivideLine />
        </div>

        <div className="w-full flex justify-center items-center flex-col">
          <div className="w-full flex my-4">
            <div className="w-1/4 text-center">Credit:</div>
            <h2 className="w-3/4 text-center">${profile.credit || 0}</h2>
          </div>
          <div className="w-full my-4 flex justify-center">
            <RiExchangeDollarLine size="2em" className="mx-1" />
            <input
              type="number"
              step="5"
              min="5"
              max="100000"
              className="border p-1"
              value={chargeValue}
              onChange={({ target }) => setChargeValue(target.value)}
            />
          </div>
          {profile?.tnc_agreed && <div className="w-full items-ceter justify-center flex">
            {profile?.name?.length >= 4 &&
              profile?.phone_number?.length >= 8 ? (
              <Checkout
                payload={{
                  email: user.email,
                  user_id: user['https://fauna.com/user_metadata'].user_id,
                  quantity: chargeValue,
                  success_url: window.location.href,
                  cancel_url: window.location.href,
                }}
              ></Checkout>
            ) : (
              <button
                onClick={() => {
                  Swal.fire({
                    title:
                      'Fill your name (at least 4 character) and phone number (8 digit) before you top up.',
                    footer:
                      'There is no expiratory date on credits. Credits are for class enrollment only and are non-transferable.\
        If you require a refund, there shall be a 5% surcharge on your remaining credits under ALL circumstances.',
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                  });
                }}
                className="px-11 py-2 bg-gray-400 hover:bg-gray-500 rounded-full text-white"
              >
                {!loading ? 'Topup' : 'Loading'}
              </button>
            )}
          </div>}
        </div>
      </div>
    </>
  );
};

export default Profile;

import { Paginate,Documents,Collection,Lambda,Get,Var,Map, Match, Index} from "faunadb";
import faunadb from "faunadb";
const q = faunadb.query;
const { Call } = q;

const ADD_TEACHER = async (client, name, email, password, setClient) => {
  try {
    let response = await client.query(
      Call(q.Function("add_teacher"), {
        name: name,
        email: email,
        password: password,
        deleted: false,
      })
    );
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
};

export const getAllTeachers = async (client, size, cursor) => {
  try {
    let response = await client.query(
      Map(
        Paginate(Match(Index("allTeachers")), { size: 999 }),
        Lambda('teacherRef', Get(Var('teacherRef')))
      )
    );
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
};

const exportItems = { ADD_TEACHER };

export default exportItems;

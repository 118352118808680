import { Client } from "fauna";
import faunadb from "faunadb";

export const fnClient = (token = null) =>
  new faunadb.Client({
    secret: token || process.env.REACT_APP_PRE_LOGIN_KEY,
  });

export const fnV10Client = (token = null) =>
  new Client({
    secret: token || process.env.REACT_APP_PRE_LOGIN_KEY,
  });
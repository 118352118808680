import { fql } from 'fauna';
import faunadb, { And, Equals, LTE, TimeDiff, ToDate } from 'faunadb';
const q = faunadb.query;
const {
  Call,
  Update,
  Ref,
  Collection,
  Map,
  Paginate,
  Range,
  Match,
  Index,
  Get,
  Select,
  Now,
  TimeAdd,
  Lambda,
  Var,
  CurrentIdentity,
  If,
  GTE,
  Let,
  Do,
  Abort,
  Subtract,
  Add,
  ContainsValue,
  Merge,
  Not,
  Filter,
  ContainsField,
  ContainsPath,
} = q;

export const getMyProfile = async (client) => {
  const query = fql`Query.identity()`;
  const response = await client.query(query);
  return response.data;
};

export const getMyCourses = async (client) => {
  const query = fql`course_students.byStudent(Student(Query.identity()!.id)).pageSize(999){
      id: .courseID.id,
      name: .courseID.name,
      quota: .courseID.quota,
      price: .courseID.price,
      weekday: .courseID.weekday,
      start_at: .courseID.start_at,
      teacher: .courseID.teacher {
        name
      },
      term: .courseID.term {
        id,
        name,
        start_date,
        end_date,
        classroom: .classroom.name
      }
    }`;
  const response = await client.query(query);
  return response.data;
};

export const getMyOrders = async (client) => {
  const query = fql`Order.byStudent(Student(Query.identity()!.id)).pageSize(999) {
    canceled,
    price,
    date,
    course {
      id,
      name,
      quota,
      price,
      weekday,
      start_at,
      teacher {
        name
      },
      term {
        id,
        name,
        start_date,
        end_date,
        classroom: .classroom.name
      }
    }
  }`;

  const response = await client.query(query);
  return response.data;
}

export const getMyLogs = async (client) => {
  const query = fql`
    let logs = Log.all_logs_by_target(Student(Query.identity()!.id)).take(10).concat(Log.all_logs_by_source(Student(Query.identity()!.id)).take(10)){
      from,
      to,
      date,
      action
    }
    logs`;
  const response = await client.query(query);
  return response.data;
}


export const updateStudentName = async (client, name, phone_number) => {
  const query = fql`
    Query.identity().coll(Query.identity().id).update({name:${name}, phone_number:${phone_number}})
  `;
  const response = await client.query(query);
  return response.data;
}

export const agreeTnc = async (client) => {
  const query = fql`
  Query.identity().coll(Query.identity().id).update({tnc_agreed: true})
  `;
  const response = await client.query(query);
  return response.data;
};

// ^^^^ v10

// v4

export const apply_course = async (client, userId, CourseId) => {
  try {
    let response = await client.query(
      Call(q.Function('apply_course'), userId, CourseId)
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const get_my_coming_classes = async (client) => {
  try {
    let response = await client.query(
      Call(q.Function('get_my_up_coming_class'))
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const get_active_courses = async (client) => {
  try {
    let response = await client.query(
      Let(
        {
          activeTerms: Select(
            ['data'],
            Filter(
              Paginate(Match(Index('allTerms'))),
              Lambda(
                'term',
                And(
                  GTE(
                    ToDate(Now()),
                    Select(['data', 'start_date'], Get(Var('term')))
                  ),
                  LTE(
                    ToDate(Now()),
                    Select(['data', 'end_date'], Get(Var('term')))
                  )
                )
              )
            )
          ),
          studentCourses: Select(
            ['data'],
            Paginate(
              Match(Index('course_students_by_student'), CurrentIdentity())
            )
          ),
        },
        Filter(
          Map(
            Var('studentCourses'),
            Lambda(
              'course',
              If(
                ContainsValue(
                  Select(['data', 'term'], Get(Var('course'))),
                  Var('activeTerms')
                ),
                Merge(Select(['data'], Get(Var('course'))), {
                  term: Select(
                    ['data'],
                    Get(Select(['data', 'term'], Get(Var('course'))))
                  ),
                  teacher: Select(
                    ['data'],
                    Get(Select(['data', 'teacher'], Get(Var('course'))))
                  ),
                }),
                {}
              )
            )
          ),
          Lambda('item', Not(Equals(Var('item'), {})))
        )
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const release_class = async (client, ticket_id, tier_type) => {
  let t0Add = 0;
  let t1Add = 0;
  let t2Add = 0;
  let logMessage = '';
  if (tier_type === 'catch_up_credit_tier_0') {
    t0Add = 1;
    logMessage = 'release + 1 🥉';
  }
  if (tier_type === 'catch_up_credit_tier_1') {
    t1Add = 1;
    logMessage = 'release + 1 🥈';
  }
  if (tier_type === 'catch_up_credit_tier_2') {
    t2Add = 1;
    logMessage = 'release + 1 🥇';
  }
  try {
    let response = await client.query(
      Let(
        {
          student: CurrentIdentity(),
          ticket: Get(Ref(Collection('AttendanceTicket'), ticket_id)),
          term: Select(
            ['data'],
            Get(
              Select(
                ['data', 'term'],
                Get(Select(['data', 'course'], Var('ticket')))
              )
            )
          ),
        },
        Do(
          If(
            LTE(
              TimeDiff(
                ToDate(TimeAdd(Now(), 8, 'hours')),
                Select(['start_date'], Var('term')),
                'days'
              ),
              0
            ),
            true,
            Abort(
              'Not in catch up period. Catch up period start after Term start.'
            )
          ),
          Update(Var('student'), {
            data: {
              catch_up_credit_tier_0: Add(
                Select(['data', 'catch_up_credit_tier_0'], Get(Var('student'))),
                t0Add
              ),
              catch_up_credit_tier_1: Add(
                Select(['data', 'catch_up_credit_tier_1'], Get(Var('student'))),
                t1Add
              ),
              catch_up_credit_tier_2: Add(
                Select(['data', 'catch_up_credit_tier_2'], Get(Var('student'))),
                t2Add
              ),
            },
          }),
          Call(q.Function('add_log'), [
            'Student',
            Select(['id'], CurrentIdentity()),
            'Class',
            Select(['id'], Select(['data', 'session'], Var('ticket'))),
            logMessage,
          ]),
          Update(Ref(Collection('AttendanceTicket'), ticket_id), {
            data: {
              student: 'None',
              attended: false,
            },
          })
        )
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const fetch_catch_up_classes = async (client) => {
  try {
    let response = await client.query(
      Filter(
        Map(
          Paginate(
            Range(
              Match(Index('attendance_tickets_by_student_with_date'), 'None'),
              Now(),
              TimeAdd(Now(), 180, 'days')
            ),
            {
              size: 9999,
            }
          ),
          Lambda('ticket', {
            ticket_id: Select(['id'], Select(1, Var('ticket'))),
            attended: Select(
              ['data', 'attended'],
              Get(Select(1, Var('ticket')))
            ),
            date: Select(['data', 'date'], Get(Select(1, Var('ticket')))),
            deleted: Select(
              ['data', 'deleted'],
              Get(Select(['data', 'course'], Get(Select(1, Var('ticket')))))
            ),
            teacher: Select(
              ['data'],
              Get(
                Select(
                  ['data', 'teacher'],
                  Get(Select(['data', 'course'], Get(Select(1, Var('ticket')))))
                )
              )
            ),
            course: Select(
              ['data', 'name'],
              Get(Select(['data', 'course'], Get(Select(1, Var('ticket')))))
            ),
            term: Select(
              ['data'],
              Get(
                Select(
                  ['data', 'term'],
                  Get(Select(['data', 'course'], Get(Select(1, Var('ticket')))))
                )
              )
            ),
            classroom: Select(
              ['data', 'name'],
              Get(
                Select(
                  ['data', 'classroom'],
                  Get(
                    Select(
                      ['data', 'term'],
                      Get(
                        Select(
                          ['data', 'course'],
                          Get(Select(1, Var('ticket')))
                        )
                      )
                    )
                  )
                )
              )
            ),
          })
        ),
        Lambda(
          'ticket',
          And(
            GTE(
              ToDate(TimeAdd(Now(), 8, 'hour')),
              Select(['term', 'start_date'], Var('ticket'))
            ),
            LTE(
              ToDate(TimeAdd(Now(), 8, 'hour')),
              Select(['term', 'end_date'], Var('ticket'))
            ),
            Equals(Select(['deleted'], Var('ticket')), false)
          )
        )
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const get_catch_up_credits = async (client) => {
  try {
    let response = await client.query(
      Let(
        {
          student: Get(CurrentIdentity()),
        },
        {
          catchUpCreditTier0: Select(
            ['data', 'catch_up_credit_tier_0'],
            Var('student')
          ),
          catchUpCreditTier1: Select(
            ['data', 'catch_up_credit_tier_1'],
            Var('student')
          ),
          catchUpCreditTier2: Select(
            ['data', 'catch_up_credit_tier_2'],
            Var('student')
          ),
        }
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const catch_up_class = async (
  client,
  ticket_id,
  credit,
  creditLevel
) => {
  let t0deduct = 0;
  let t1deduct = 0;
  let t2deduct = 0;
  let logMessage = '';
  if (creditLevel === 'catch_up_credit_tier_0') {
    t0deduct = 1;
    logMessage = `paid $${credit} and -1 🥉 to catch`;
  }
  if (creditLevel === 'catch_up_credit_tier_1') {
    t1deduct = 1;
    logMessage = `paid $${credit} and -1 🥈 to catch`;
  }
  if (creditLevel === 'catch_up_credit_tier_2') {
    t2deduct = 1;
    logMessage = `paid $${credit} and -1 🥇 to catch`;
  }
  if (creditLevel === 'catch_up_credit_tier_na') {
    logMessage = 'paid $350 catch up';
  }

  try {
    let response = await client.query(
      Let(
        {
          student: Get(CurrentIdentity()),
          ticket: Get(Ref(Collection('AttendanceTicket'), ticket_id)),
          studentList: Select(
            ['data'],
            Map(
              Paginate(
                Match(
                  Index('attendance_tickets_by_class'),
                  Select(['data', 'session'], Var('ticket'))
                ),
                { size: 100 }
              ),
              Lambda('t', Select(['data', 'student'], Get(Var('t'))))
            )
          ),
          term: Select(
            ['data'],
            Get(
              Select(
                ['data', 'term'],
                Get(Select(['data', 'course'], Var('ticket')))
              )
            )
          ),
        },
        Do(
          If(
            ContainsValue(CurrentIdentity(), Var('studentList')),
            Abort('You are in this class already.'),
            true
          ),
          If(
            GTE(Select(['data', 'credit'], Var('student')), credit),
            true,
            Abort('Not enough credit')
          ),
          If(
            Equals(Select(['data', 'student'], Var('ticket')), 'None'),
            true,
            Abort('Class is full')
          ),
          Update(CurrentIdentity(), {
            data: {
              credit: Subtract(
                Select(['data', 'credit'], Var('student')),
                credit
              ),
              catch_up_credit_tier_0: Subtract(
                Select(['data', 'catch_up_credit_tier_0'], Var('student')),
                t0deduct
              ),
              catch_up_credit_tier_1: Subtract(
                Select(['data', 'catch_up_credit_tier_1'], Var('student')),
                t1deduct
              ),
              catch_up_credit_tier_2: Subtract(
                Select(['data', 'catch_up_credit_tier_2'], Var('student')),
                t2deduct
              ),
            },
          }),
          If(
            GTE(Select(['data', 'credit'], Var('student')), 0),
            true,
            Abort('Not enough credit')
          ),
          If(
            GTE(Select(['data', 'catch_up_credit_tier_0'], Var('student')), 0),
            true,
            Abort('Not enough 🥉 Catch Up Point')
          ),
          If(
            GTE(Select(['data', 'catch_up_credit_tier_1'], Var('student')), 0),
            true,
            Abort('Not enough 🥈 Catch Up Point')
          ),
          If(
            GTE(Select(['data', 'catch_up_credit_tier_2'], Var('student')), 0),
            true,
            Abort('Not enough 🥇 Catch Up Point')
          ),
          Call(q.Function('add_log'), [
            'Student',
            Select(['id'], CurrentIdentity()),
            'Class',
            Select(['id'], Select(['data', 'session'], Var('ticket'))),
            logMessage,
          ]),
          Update(Ref(Collection('AttendanceTicket'), ticket_id), {
            data: {
              student: CurrentIdentity(),
              catchup: true,
            },
          })
        )
      )
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

export const get_my_latest_logs = async (
  client,
  type,
  id,
  size,
  before,
  after
) => {
  try {
    let response = await client.query(
      Call(q.Function('get_my_latest_logs'), [type, id, size, before, after])
    );
    return response;
  } catch (error) {
    return JSON.parse(error.requestResult.responseRaw);
  }
};

const exportItems = {
  apply_course,
  get_my_coming_classes,
  release_class,
  fetch_catch_up_classes,
  catch_up_class,
  get_catch_up_credits,
  get_active_courses,
  get_my_latest_logs,
};

export default exportItems;

import { fql } from 'fauna';
import { Get, Index, Lambda, Map, Match, Paginate, Var, Let, Filter, Select, Documents, Collection, Do, Abort, GTE, Count, And, Equals, If, Ref, Exists, Create, Update, Subtract, CurrentIdentity, ToDouble, LT, Time, LTE, Call, Function, Concat } from 'faunadb';

//v10
export const getActivePerformanceTerm = async (client) => {
    const query = fql`
        Performance.byDeleted(false).where(.performance_term.deleted == false && .performance_term.show_on_dashboard == true).pageSize(999) {id, data}
    `;
    const response = await client.query(query);
    const data = response?.data;
    const performanceTerm = data.data[0]?.data.performance_term;
    const performanceDays = [];

    data.data.forEach(performance => {
        const performance_day = performance.data.performance_day;
        let performance_day_exists = performanceDays.some(existing_performance_day => existing_performance_day.performance_day.id === performance_day.id);
        if (!performance_day_exists) {
            performanceDays.push({ performance_day, performances: [] });
        }
    });

    data.data.forEach(performance => {
        const performance_day = performance.data.performance_day;
        performanceDays.forEach(existing_performance_day => {
            if (existing_performance_day.performance_day.id === performance_day.id) {
                existing_performance_day.performances.push({ id: performance.id, ...performance.data });
            }
        });
    });

    return { performanceTerm , performanceDays};
}

export const getMyPerformances = async (client) => {
    const query = fql`performance_performers.byStudent(Student(Query.identity()!.id)).pageSize(9999)`;
    const response = await client.query(query);
    return response.data;
}


//TODO move this function to dashboard
export const insert_student_to_performance = async ({ client, performanceId }) => {
    // try {
    let response = await client.query(
        Let(
            {
                performance: Get(Ref(Collection("Performance"), performanceId)),
                performanceTermRef: Select(["data", "performance_term"], Var("performance")),
                performanceDayRef: Select(["data", "performance_day"], Var("performance")),
                numberOfPerformanceAppliedInADay: Count(Select(["data"], Filter(
                    Map(
                        Paginate(
                            Match(
                                Index("performance_performers_by_student"),
                                CurrentIdentity()
                            )
                        ),
                        Lambda("x", Get(Var("x")))
                    ),

                    Lambda("y",
                        And(Equals(
                            Select(["data", "performance_day"], Var("y")),
                            Var("performanceDayRef")
                        ), Equals(
                            Select(["data", "deleted"], Var("y")),
                            false
                        ))
                    )
                ))),
                price: If(
                    GTE(Var("numberOfPerformanceAppliedInADay"), 1),
                    ToDouble(0),
                    ToDouble(50)
                ),
                priceString: If(
                    GTE(Var("numberOfPerformanceAppliedInADay"), 1),
                    "0",
                    "50"
                ),
                period: If(
                    And(
                        If(
                            GTE(
                                Time("now"),
                                Select(["data", "application_start_date"], Get(Var("performanceTermRef")))
                            ),
                            true,
                            false
                        ),
                        If(
                            LTE(
                                Time("now"),
                                Select(["data", "application_end_date"], Get(Var("performanceTermRef")))
                            ),
                            true,
                            false
                        )
                    ),
                    true,
                    false
                )
            },
            Do(
                // check now() within application period
                If(
                    Equals(Var("period"), false),
                    // if quota is 0, return error
                    Abort("Not in Application Period!"),
                    true
                ),
                // check if performance's quota if >= 1
                If(
                    LTE(Select(["data", "quota"], Get(Ref(Collection('Performance'), performanceId))), 0),
                    // if quota is 0, return error
                    Abort("Performance quota is 0"),
                    true
                ),
                //check if a student have <= 3 records , first use Index(performance_performers_by_student) wth studentId input to get all document and filter record which contain performanceDayId
                If(
                    GTE(Var("numberOfPerformanceAppliedInADay"), 3),
                    Abort("One Student can only apply at most 3 performances in one day"),
                    true

                ),

                // check if student+performance exist in performance_performers using Index("performance_performers_by_performance_and_student")
                If(
                    Exists(
                        Match(
                            Index('performance_performers_by_performance_and_student'),
                            Ref(Collection('Performance'), performanceId),
                            CurrentIdentity()
                        )
                    ),
                    // if exists, return error
                    Abort("Student already in performance"),
                    // if not exists, insert student+performance to performance_performers using Index("performance_performers_by_performance_and_student")
                    true),
                // check if user has enough credit
                If(
                    GTE(Select(["data", "credit"], Get(CurrentIdentity())), Var("price")),
                    // if quota is 0, return error
                    true,
                    Abort("Not Enough credit")

                ),
                Create(Collection('performance_performers'), {
                    data: {
                        performance_term: Var("performanceTermRef"),
                        performance_day: Var("performanceDayRef"),
                        performanceID: Ref(Collection('Performance'), performanceId),
                        studentID: CurrentIdentity(),
                        paid: Var("price")
                    }
                }),
                // update performance quota by -1
                Update(Ref(Collection('Performance'), performanceId), {
                    data: {
                        quota: Subtract(Select(["data", "quota"], Get(Ref(Collection('Performance'), performanceId))), 1)
                    }
                }),
                Update(CurrentIdentity(), {
                    data: {
                        credit: Subtract(Select(["data", "credit"], Get(CurrentIdentity())), Var('price'))
                    }
                }),
                Create(Collection("Log"), {
                    data: {
                        from: CurrentIdentity(),
                        to: Ref(Collection('Performance'), performanceId),
                        action: Concat(["Paid", "$", Var("priceString"), "to apply"], " "),
                        date: Time("now")
                    }
                }),
                {
                    status: "success",
                    message: Concat(["Paid", "$", Var("priceString")], " ")
                }
            )
        )
    )
    return response;
}

import React, { createContext, useContext } from 'react';
import faunadb from 'faunadb';
import queries from '../faunadb/queries/index';
import { useAuth0 } from '@auth0/auth0-react';

const FaunaContext = createContext({});

export const useFaunaContext = () => {
  const context = useContext(FaunaContext);
  if (!context) {
    throw new Error('FaunaContext not exists');
  }
  return context;
};

const FaunaProvider = (props) => {
  const { user } = useAuth0();
  const client = user
    ? new faunadb.Client({
        secret: user['https://fauna.com/user_metadata']?.secret,
      })
    : props.client;

  const value = {
    queries,
    client,
  };

  return (
    <FaunaContext.Provider value={value} {...props}></FaunaContext.Provider>
  );
};

export default FaunaProvider;

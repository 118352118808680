import React, { useState } from 'react';
import moment from 'moment';
import CatchUpCard from './CatchUpCard';
import {
  Badge,
  Button,
  Calendar,
  Container,
  Modal,
  Pagination,
  Stack,
  Table,
  Toggle,
} from 'rsuite';
import { useHistory } from 'react-router-dom';
const CatchUpSummary = ({
  catchupClasses = [],
  fetch,
  catchUpCreditTier0,
  catchUpCreditTier1,
  catchUpCreditTier2,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [filteredClass, setFilteredClass] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [calanderMode, setCalanderMode] = useState(false);

  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const paginatedData = catchupClasses.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const handleOpen = (value) => {
    setFilteredClass(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const renderCell = (date) => {
    let filteredList = catchupClasses.filter(
      (c) =>
        moment(c.date.value).format('YYYY-MM-DD') ===
        moment(date).format('YYYY-MM-DD')
    );
    if (filteredList?.length > 2) {
      return <Badge content={`${filteredList?.length} classes...`} />;
    }
    if (filteredList.length <= 2) {
      return (
        <ul>
          {filteredList.map((item, index) => (
            <li key={index}>
              <Badge /> <b>{moment(item.date.value).format('HH:mm')}</b> -{' '}
              {item.course}
            </li>
          ))}
        </ul>
      );
    }
  };

  const onSelectCell = (date) => {
    setSelectedDate(moment(date).format('YYYY-MM-DD'));
    let filteredList = catchupClasses.filter(
      (c) =>
        moment(c.date.value).format('YYYY-MM-DD') ===
        moment(date).format('YYYY-MM-DD')
    );
    if (filteredList.length > 0) {
      handleOpen(filteredList);
    }
  };

  const onSelectListCell = (ticket_id) => {
    let filteredList = catchupClasses.filter((c) => c.ticket_id === ticket_id);
    if (filteredList.length > 0) {
      handleOpen(filteredList);
    }
  };

  return (
    <>
      <Toggle
        style={{ margin: ' 12px' }}
        size="lg"
        checkedChildren="Calendar Mode"
        unCheckedChildren="Calendar Mode"
        onChange={(value) => setCalanderMode(value)}
      />
      {!calanderMode && (
        <Container>
          <Table
            style={{ zIndex: 0 }}
            data={paginatedData}
            autoHeight
            wordWrap="break-word"
            virtualized
          >
            <Table.Column flexGrow={2} minWidth={100}>
              <Table.HeaderCell>Course</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return `[${rowData?.classroom}] ${rowData?.course} `;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} autoHeight>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return `${rowData?.teacher?.name}`;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={2} minWidth={150} autoHeight>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return moment(rowData.date.value).format('HH:mm, ddd, ll ');
                }}
              </Table.Cell>
            </Table.Column>
            {/* <Table.Column flexGrow={2} minWidth={100}>
              <Table.HeaderCell>Classroom</Table.HeaderCell>
              <Table.Cell dataKey="classroom"></Table.Cell>
            </Table.Column> */}
            <Table.Column flexGrow={1} fixed="right">
              <Table.HeaderCell></Table.HeaderCell>

              <Table.Cell style={{ padding: '5px 0px' }}>
                {(rowData) => (
                  <Button
                    color="orange"
                    appearance="ghost"
                    onClick={() => onSelectListCell(rowData.ticket_id)}
                  >
                    Catch
                  </Button>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              style={{ justifyContent: 'end' }}
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={3}
              size="xs"
              layout={['pager', '|', 'limit']}
              total={catchupClasses.length}
              limitOptions={[10, 20, 30]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </Container>
      )}
      {calanderMode && (
        <Calendar
          bordered
          renderCell={renderCell}
          onSelect={onSelectCell}
          isoWeek
        />
      )}
      <Modal open={open} onClose={handleClose} size={'lg'}>
        <Modal.Header>
          <Modal.Title
            className="text-2xl font-bold"
            style={{
              color: '#917a61',
            }}
          >
            Catch up - {selectedDate}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Stack
              wrap
              spacing={16}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-around'}
            >
              {filteredClass.map((c) => (
                <CatchUpCard
                  handleClose={handleClose}
                  className="w-64"
                  c={c}
                  key={`${c.ticket_id}`}
                  fetch={fetch}
                  catchUpCreditTier0={catchUpCreditTier0}
                  catchUpCreditTier1={catchUpCreditTier1}
                  catchUpCreditTier2={catchUpCreditTier2}
                  history={history}
                />
              ))}
            </Stack>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearence="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CatchUpSummary;

import faunadb from "faunadb";
const q = faunadb.query;
const { Call } = q;

const login = async (client, email, password, setClient) => {
  try {
    let response = await client.query(
      Call(q.Function("login_teacher"), {
        email: email,
        password: password,
      })
    );
    setClient(new faunadb.Client({ secret: response }));
    sessionStorage.setItem("access_token", response);
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
};

const exportItem = { login };
export default exportItem;

import faunadb from "faunadb";
const q = faunadb.query;
const { Call } = q;

const get_course_final_price = async (client, studentId, courseId) => {
  try {
    let response = await client.query(
      Call(q.Function("get_final_course_price"), [studentId, courseId])
    );
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
};

const exportItems = {
  get_course_final_price,
};

export default exportItems;

import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

import faunadb from 'faunadb';
const q = faunadb.query;
const {
  If,
  ContainsPath,
} = q;

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}



export const weekdayOptions = [
  {
    label: 'Monday',
    value: 1,
  },
  {
    label: 'Tuesday',
    value: 2,
  },
  {
    label: 'Wednesday',
    value: 3,
  },
  {
    label: 'Thursday',
    value: 4,
  },
  {
    label: 'Friday',
    value: 5,
  },
  {
    label: 'Saturday',
    value: 6,
  },
  {
    label: 'Sunday',
    value: 7,
  },
];

export const weekdayArray = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const shortWeekdayArray = [
  'Mon',
  'Tue',
  'Wed',
  'Thur',
  'Fri',
  'Sat',
  'Sun',
];

export const flattenTermByStatus = ({ data }) => {
  let courseList = [];
  data.forEach(({term, courses}) => {
    courses.data.forEach(course => {
      courseList.push({
        termId: term.id,
        termName: term.data.name,
        termStartDate: term.data.start_date,
        termEndDate: term.data.end_date,
        courseId: course.id,
        courseName: course.data.name,
        coursePrice: course.data.price,
        courseQuota: course.data.quota,
        courseWeekday: course.data.weekday,
        courseStartAt: course.data.start_at,
        courseDuration: course.data.duration,
        courseDeleted: course.data.deleted,
        teacherName: course.data.teacher.name,
        classroomName: term.data.classroom.name,
      })
    })
  })
  return courseList;
};

export const getUniqueTermsByCourse = (courses) => {
  let terms = [];

  courses.map((course) => {
    let existed = false;
    terms.map((term) => {
      if (course.term._id === term._id) {
        existed = true;
      }
    });
    if (!existed) {
      terms.push(course.term);
    }
  });
  terms = [...new Set(terms)];
  terms.sort(function (a, b) {
    return b._ts - a._ts;
  });
  return terms;
};

export const selectedOptionToLabelArray = (selected) => {
  let array = [];
  selected.map((item) =>
    array.push(weekdayArray.includes(item.label) ? item.value : item.label)
  );
  return array;
};

export const printErrorMessageFromFQL = (errors) => {
  errors.map((error) =>
    error.cause.map((c) => {
      toast.error(
        <FormattedMessage
          id={String(c.description)
            .toUpperCase()
            .replaceAll(' ', '_')
            .replaceAll('.', '')}
          defaultMessage={c.description}
        />
      );
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text:
          c.description === 'document is not unique.'
            ? 'You are already in this course!'
            : c.description,
      });
    })
  );
};

export const determineCredit = (classTime, c, isRelease = true) => {
  const lateRelease =
    moment.duration(classTime.diff(moment(), 'mintues')).asMinutes() < 120;
  const afternoon = classTime.hour() <= 18 && classTime.minutes() < 45;
  const evening = !afternoon;
  const isWeekend = classTime.day() === 6 || classTime.day() === 0;
  const isWeekday = !isWeekend;
  const isBlendy = ['bendy'].includes(c.course.toLowerCase());
  const isIntro = ['intro'].includes(c.course.toLowerCase());
  const isExoticBasic = ['exotic basic'].includes(c.course.toLowerCase());
  if (lateRelease && isRelease) return false;
  if (isExoticBasic) return 'catch_up_credit_tier_1';
  if (isBlendy) return 'catch_up_credit_tier_0';
  if (isIntro) return 'catch_up_credit_tier_1';
  if (isWeekday && afternoon) return 'catch_up_credit_tier_1';
  if (isWeekday && evening) return 'catch_up_credit_tier_2';
  if (isWeekend) return 'catch_up_credit_tier_2';
  return 'catch_up_credit_tier_2';
};

export const FilterRow = styled.div`
  margin: 2rem 0;
  display: flex;
  & > div {
    flex: 0.333;
    margin: 0px 5px;
    @media (max-width: 576px) {
      margin: 5px 0px;
    }
  }
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export function groupByClassroom(list) {
  const result = {};
  list.forEach((item) => {
    const classroom = item.classroom.name;
    if (!result[classroom]) {
      result[classroom] = [];
    }
    result[classroom].push(item);
  });
  return result;
}

function Select(path, v){
  return If(ContainsPath(path,v),q.Select(path, v),null)
}

export const useQuerySetting = {
  retryDelay: 5000,
  retryOnMount:false,
  retry:1,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
}
import moment from 'moment';
import React, { useState } from 'react';
import {
  Badge,
  Calendar,
  Container,
  Header,
  Loader,
  Content,
  Modal,
  Stack,
  Button,
  Table,
} from 'rsuite';
import { shortWeekdayArray } from '../../common/utils';
import useActiveEnrolledCourseHook from '../../hooks/useActiveEnrolledCourseHook';
import useMyComingClassHook from '../../hooks/useMyComingClassHook';
import AttendaceCard from './components/AttendanceCard';
import styled from 'styled-components';
import TermContainer from '../../components/application/TermContainer';
import { useFaunav10Context } from '../../providers/FaunaV10Provider';
import { useQuery } from 'react-query';
import { getActiveTermV10 } from '../../faunadb/queries/term';

const ContainerT = styled.div`
  max-width: 450px;
  min-width: 350px;
  padding: 10px 20px;
  margin: 10px 0px 10px 0px;
  background: white;
  border-radius: 30px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.16);
  color: #917a61;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & h2 {
    font-weight: 700;
    font-size: 1.5em;
  }
  & h4 {
    font-weight: 500;
    font-size: 1em;
  }
`;

const MyClassPage = () => {
  const [open, setOpen] = useState(false);
  const [filteredClass, setFilteredClass] = useState([]);
  const handleOpen = (value) => {
    setFilteredClass(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const {
    data: classes,
    isSuccess: isGetClassesSuccess,
    isLoading: isGetClassesLoading,
    refetch: refetchComingClass,
  } = useMyComingClassHook();

  const {
    data: courses,
    isSuccess: isGetCoursesSuccess,
    isLoading: isGetCoursesLoading,
  } = useActiveEnrolledCourseHook();

  const onSelectCell = (date) => {
    if (isGetCoursesSuccess) {
      let filteredList = classes.data.filter(
        (c) =>
          moment(c.date.value).format('YYYY-MM-DD') ===
          moment(date).format('YYYY-MM-DD')
      );
      if (filteredList.length > 0) {
        handleOpen(filteredList);
      }
    }
  };

  const { client: v10Client } = useFaunav10Context();
  const { data: activeTermV10 } = useQuery(['activeTerm', 'v10'], () =>
    getActiveTermV10(v10Client)
  );

  let weeks = []
  const printWeekNumber = () => {
    let startDate = moment(activeTermV10?.terms[0]?.data.start_date.dateString).local().startOf('day').day(1)
    let endDate = moment(activeTermV10?.terms[0]?.data.end_date.dateString).local().startOf('day').day(7)
    while (startDate <= endDate) {
      if (startDate.format('dddd') === 'Monday') {
        weeks.push(startDate.format('YYYY-MM-DD'))
      }
      startDate = startDate.add(1, 'day')
    }
  }
  printWeekNumber()
  const renderCell = (date) => {
    let weekNumber = weeks.indexOf(moment(date).local().startOf('day').format('YYYY-MM-DD'))


    let filteredList = classes?.data.filter(
      (c) =>
        moment(c.date.value).format('YYYY-MM-DD') ===
        moment(date).format('YYYY-MM-DD')
    );
    return (
      <div>
        {weekNumber > -1 && <Badge content={`W${weekNumber + 1}`} color="blue" />}
        <ul>
          {filteredList.map((item, index) => (
            <li key={index}>
              {item?.catchup ? <Badge color="violet" content="C" /> : <Badge />}{' '}
              <b>{moment(item.date.value).format('HH:mm')}</b> - {item.course}
            </li>
          ))
          }
        </ul >
      </div >
    );
  };
  if (isGetClassesLoading)
    return <Loader backdrop content="loading..." vertical />;
  return (
    <Container>
      <div className="flex flex-col justify-center items-center w-full">
        <ContainerT>
          <TermContainer/>
        </ContainerT>
      </div>
      <Header>
        <h2
          className="font-bold text-2xl"
          style={{
            color: '#917a61',
          }}
        >
          Active Enrolled Courses
        </h2>
        <Content>
          <Table data={courses} autoHeight={true} loading={isGetCoursesLoading}>
            {/* <Table.Column flexGrow={200} fixed>
              <Table.HeaderCell>Term</Table.HeaderCell>
              <Table.Cell dataKey="term.name" />
            </Table.Column> */}
            <Table.Column flexGrow={200}>
              <Table.HeaderCell>Course</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>
            <Table.Column flexGrow={200}>
              <Table.HeaderCell>Teacher</Table.HeaderCell>
              <Table.Cell dataKey="teacher.name" />
            </Table.Column>
            <Table.Column flexGrow={200}>
              <Table.HeaderCell>Weekday</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return `${shortWeekdayArray[rowData.weekday - 1]} ${rowData?.start_at
                    }`;
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        </Content>
      </Header>
      {isGetClassesSuccess && (
        <Content>
          <Calendar
            bordered
            renderCell={renderCell}
            onSelect={onSelectCell}
            isoWeek
          />
        </Content>
      )}
      <Modal open={open} onClose={handleClose} size={'lg'}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Stack
              wrap
              spacing={16}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-around'}
            >
              {filteredClass.map((c) => {
                if (c?.deleted === true) return <></>;
                else
                  return (
                    <AttendaceCard
                      className="w-64"
                      c={c}
                      key={c.date}
                      fetch={refetchComingClass}
                      handleClose={handleClose}
                    />
                  );
              })}
            </Stack>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearence="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default MyClassPage;

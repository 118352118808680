import { Client, fql } from 'fauna';
import { Collection, Get, Index, Lambda, Map, Match, Paginate, Var,Ref } from 'faunadb';

export const getGeneralSettingV10 = async (client) => {
  const response = await client.query(fql`GeneralSetting.byId(${process.env.REACT_APP_PERIOD_SETTING})`);
  return response.data;
}

export const getGeneralSetting = async (client, id) => {
  try {
    let response = await client.query(
      Get(Ref(Collection('GeneralSetting'), id))
    );
    return response?.data;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
}

export const getAnnouncement = async (client) => {
  try {
    let response = await client.query(
      Map(
        Paginate(Match(Index('notification_by_show'), true)),
        Lambda('noti', Get(Var('noti')))
      )
    );
    return response;
  } catch (error) {
    throw Error(error.requestResult.responseRaw);
  }
};

import { useQuery } from 'react-query';
import { get_my_coming_classes } from '../faunadb/queries/student';
import { useFaunaContext } from '../providers/FaunaProvider';

const useMyComingClassHook = () => {
  const { client } = useFaunaContext();
  return useQuery(
    ['my_coming_classes'],
    async () => await get_my_coming_classes(client)
  );
};

export default useMyComingClassHook;

import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import React from 'react';
import { Container, Header, Table } from 'rsuite';
import useMyLogs from '../hooks/useMyLogs';

const MyLogs = () => {
  const { data, isLoading } = useMyLogs();
  const { user } = useAuth0();
  return (
    <Container style={{ width: '100%' }}>
      <Header>
        <h2
          className="font-bold text-2xl"
          style={{
            color: '#917a61',
          }}
        >
          My Activity Log
        </h2>
      </Header>
      <Table
        loading={isLoading}
        autoHeight={true}
        data={data?.data.filter((r) => r?.action !== 'logged in').sort((a,b)=> {
          return new Date(b.date.isoString) - new Date(a.date.isoString)
      })}
      >
        <Table.Column width={200} flexGrow={1}>
          <Table.HeaderCell>Time</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return moment(rowData.date.isoString).format('YYYY-MM-DD HH:mm');
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100} flexGrow={1}>
          <Table.HeaderCell>Source</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              if (rowData?.from?.email === user.email)
                return 'Myself';
              return rowData.from.email;
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200} flexGrow={2}>
          <Table.HeaderCell>Action</Table.HeaderCell>
          <Table.Cell dataKey="action"></Table.Cell>
        </Table.Column>
        <Table.Column width={300} flexGrow={2}>
          <Table.HeaderCell>To</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              if (rowData?.toCourse)
                return `${moment(rowData?.to?.date?.isoString).format(
                  'YY-MM-DD hh:mm'
                )} ${rowData.toCourse.name} `;
              if (rowData?.to?.email === user.email)
                return 'Myself';
              if (rowData?.to?.name)
                return rowData?.to?.name;
              if (rowData?.to?.performance_name)
                return `Performance: ${rowData?.to?.performance_name}`
              return rowData.to.email;
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Container>
  );
};

export default MyLogs;

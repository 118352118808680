import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { getMyLogs, get_my_latest_logs } from '../../../faunadb/queries/student';
import { useFaunaContext } from '../../../providers/FaunaProvider';
import { useFaunav10Context } from '../../../providers/FaunaV10Provider';

const useMyLogs = () => {
  const {client} = useFaunav10Context();
  return useQuery(
    ['get_my_latest_logs'],
    () => getMyLogs(client),
    {
      retryDelay: 5000,
      retryOnMount:false,
      retry:1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export default useMyLogs;
